import 'bootstrap';
import 'slick-carousel';


$(document).ready(function(){

	/* ------------------------------------------------------------------------ */
	/* TOPBAR SCROLL DOWN */
	/* ------------------------------------------------------------------------ */ 

$( window ).scroll(function() {
	var scrollPosition = $(this).scrollTop();
	if ( scrollPosition > 300 ) {
    	$('.topbar').addClass('scrolled');
    	$('.top_logo').attr("src","public/assets/images/logo-footer.png");
    } else {
    	$('.topbar').removeClass('scrolled');
    	$('.top_logo').attr("src","public/assets/images/logo.png");
    }
});

    /* ------------------------------------------------------------------------ */
    /* HEADBANNER INNER BACKGROUND SRC */
    /* ------------------------------------------------------------------------ */

$(window).on('load',function(){
    var imageBackground = $('.headbanner').data("src");
    $('.headbanner').css({
        'background':'url('+ imageBackground +') no-repeat',
        'background-size':'cover',
        'background-position':'top center'
    });
})

    /* ------------------------------------------------------------------------ */
    /* SLIDER ACCUEIL */
    /* ------------------------------------------------------------------------ */

$('.content_slider').slick({
    arrows: true,
    infinite: true,
    dots: true,
    fade: true,
    speed: 1800,
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: '<button type="button" class="slick-prev"><i class="icomoon icon-arrow-up"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="icomoon icon-arrow-down"></i></button>',
    customPaging: function(slick,index) {
        return '<button type="button" class="slick-dots-content">0' + (index + 1) + '</button>';
    },
    responsive: [
    {
      breakpoint: 576,
      settings: {
        arrows: false,
      }
    }
  ]
});

$('.realisation_slider').slick({
    arrows: true,
    slidesToShow: 4,
    swipeToSlide: true,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"><i class="icomoon icon-arrow-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="icomoon icon-arrow-right"></i></button>',
    responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

    /* ------------------------------------------------------------------------ */
    /* SCROLL ANCRE */
    /* ------------------------------------------------------------------------ */ 

$('a[href^="#"]').click(function(){
    var the_id = $(this).attr("href");
    if (the_id === '#') {
        return;
    }
    $('html, body').animate({
        scrollTop:$(the_id).offset().top
    }, 'slow');
    return false;
});

    /* ------------------------------------------------------------------------ */
    /* SETUP MENU */
    /* ------------------------------------------------------------------------ */ 

if( 'ontouchstart' in window ){ var click = 'touchstart'; }
else { var click = 'click'; }

    /* ------------------------------------------------------------------------ */
    /* MENU OPEN RESPONSIVE */
    /* ------------------------------------------------------------------------ */ 

$('div.burger').on(click, function(){
    if( !$(this).hasClass('open') ){ openMenu(); } 
    else { closeMenu(); }
}); 

function openMenu(){
    $('nav.navtop').addClass('animate');
    $('div.burger').addClass('open');
    $('div.burger-wrap').addClass('open');  
    $('body').css('overflow', 'hidden');
    
    setTimeout(function(){ 
        $('div.y').hide(); 
        $('div.x').addClass('rotate30'); 
        $('div.z').addClass('rotateneg'); 
    }, 70);     
}
    
function closeMenu(){
    setTimeout(function(){          
        $('div.burger-wrap').removeClass('open');
        $('div.burger').removeClass('open');                
        $('nav.navtop').removeClass('animate');
        $('body').css('overflow', 'initial');
        
        setTimeout(function(){          
            $('div.x').removeClass('rotate30'); 
            $('div.z').removeClass('rotateneg');            
        }, 50);
        setTimeout(function(){
            $('div.y').show();
        }, 70);
    }, 100);                                                        
}

    /* ------------------------------------------------------------------------ */
    /* SLIDER A PROPOS */
    /* ------------------------------------------------------------------------ */

$('.about_slider').slick({
    speed: 1000,
    centerMode: true,
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '0px',
    //asNavFor: '.about_text_slider',
    responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        centerPadding: '100px',
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        centerPadding: '50px',
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        centerPadding: '25px',
      }
    }
  ]
});

/*$('.about_text_slider').slick({
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    swipeToSlide: true,
    centerPadding: '0px',
    asNavFor: '.about_slider',
    dots: true,
    fade: true,
    adaptiveHeight: true,
});*/


    /* ------------------------------------------------------------------------ */
    /* REALISATION BDD */
    /* ------------------------------------------------------------------------ */

var realisation = [
    [1, 'Terrasse bois', 'public/assets/images/rea1.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea1a.jpg', 'public/assets/images/galerie_slider/rea1b.jpg','public/assets/images/galerie_slider/rea1c.jpg','public/assets/images/galerie_slider/rea1d.jpg'],'Contour piscine et terrasse bois','Conception de terrasse et cache en bois'],
    [2, 'Contour piscine et terrasse bois', 'public/assets/images/rea2.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea2a.jpg','public/assets/images/galerie_slider/rea2b.jpg','public/assets/images/galerie_slider/rea2c.jpg','public/assets/images/galerie_slider/rea2d.jpg','public/assets/images/galerie_slider/rea2e.jpg','public/assets/images/galerie_slider/rea2f.jpg','public/assets/images/galerie_slider/rea2g.jpg','public/assets/images/galerie_slider/rea2h.jpg','public/assets/images/galerie_slider/rea2i.jpg'],'Aménagement espace jardin avec terrasse en bois et jardinière','Espace jardin aménagé avec terrasse et piscine'],
    [3, 'Contour piscine', 'public/assets/images/rea3.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea3a.jpg','public/assets/images/galerie_slider/rea3b.jpg'],'Aménagement extérieur piscine','Aménagement contour piscine'],
    [4, 'Porte et fenêtres', 'public/assets/images/rea45.jpg', 'all fourniture', ['public/assets/images/galerie_slider/rea45a.jpg','public/assets/images/galerie_slider/rea45b.jpg','public/assets/images/galerie_slider/rea45c.jpg','public/assets/images/galerie_slider/rea45d.jpg','public/assets/images/galerie_slider/rea45e.jpg'],'Pose de fenêtres et portes en PVC et Aluminium','Fenêtres et portes en PVC et Aluminium'],
    [5, 'Terrasse bois', 'public/assets/images/rea5.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea5a.jpg','public/assets/images/galerie_slider/rea5b.jpg','public/assets/images/galerie_slider/rea5c.jpg','public/assets/images/galerie_slider/rea5d.jpg'],'Aménagement de terrasse extérieure','Espace terrasse bois'],
    [6, 'Jardinière', 'public/assets/images/rea6.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea6a.jpg'],'Mise en place de jardinière sur-mesure','Conception de jardinière sur-mesure'],
    [7, 'Fondations de terrasse bois', 'public/assets/images/rea7.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea7a.jpg','public/assets/images/galerie_slider/rea7b.jpg','public/assets/images/galerie_slider/rea7c.jpg','public/assets/images/galerie_slider/rea7d.jpg','public/assets/images/galerie_slider/rea7e.jpg','public/assets/images/galerie_slider/rea7f.jpg'],'Fondation et conception de terrasse bois','Fondation de Terrasse bois'],
    [8, 'Piscine et Pergola', 'public/assets/images/rea8.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea8a.jpg','public/assets/images/galerie_slider/rea8b.jpg','public/assets/images/galerie_slider/rea8c.jpg','public/assets/images/galerie_slider/rea8d.jpg','public/assets/images/galerie_slider/rea8e.jpg','public/assets/images/galerie_slider/rea8f.jpg','public/assets/images/galerie_slider/rea8g.jpg','public/assets/images/galerie_slider/rea8h.jpg','public/assets/images/galerie_slider/rea8i.jpg'],'Espace jardin, Palissade et terrasse','Jardin aménagé avec Menuiserie'],
    [9, 'Contour piscine', 'public/assets/images/rea9.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea9a.jpg','public/assets/images/galerie_slider/rea9b.jpg','public/assets/images/galerie_slider/rea9c.jpg','public/assets/images/galerie_slider/rea9d.jpg'],'Espace piscine','Contour piscine et jardinière'],
    [10, 'Piscine et façade bois', 'public/assets/images/rea10.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea10a.jpg','public/assets/images/galerie_slider/rea10b.jpg','public/assets/images/galerie_slider/rea10c.jpg','public/assets/images/galerie_slider/rea10d.jpg','public/assets/images/galerie_slider/rea10e.jpg','public/assets/images/galerie_slider/rea10f.jpg','public/assets/images/galerie_slider/rea10g.jpg','public/assets/images/galerie_slider/rea10h.jpg','public/assets/images/galerie_slider/rea10i.jpg','public/assets/images/galerie_slider/rea10j.jpg','public/assets/images/galerie_slider/rea10k.jpg'],'Aménagement jardin','Aménagement jardin et piscine'],
    [11, 'Dressing', 'public/assets/images/rea41.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea41a.jpg'],'Installation de dressing en bois','Conception de dressing bois'],
    [12, 'Dressing', 'public/assets/images/rea42.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea42a.jpg'],'Aménagement et pose de dressing','Pose de dressing bois'],
    [13, 'Fondations terrasse', 'public/assets/images/rea13.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea13a.jpg','public/assets/images/galerie_slider/rea13b.jpg','public/assets/images/galerie_slider/rea13c.jpg','public/assets/images/galerie_slider/rea13d.jpg','public/assets/images/galerie_slider/rea13e.jpg','public/assets/images/galerie_slider/rea13f.jpg','public/assets/images/galerie_slider/rea13g.jpg','public/assets/images/galerie_slider/rea13h.jpg','public/assets/images/galerie_slider/rea13i.jpg','public/assets/images/galerie_slider/rea13j.jpg','public/assets/images/galerie_slider/rea13k.jpg','public/assets/images/galerie_slider/rea13l.jpg'],'Fondations et création de terrasse','Conception et pose de grande terrasse en bois'],
    [14, 'Jardin bois', 'public/assets/images/rea14.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea14a.jpg','public/assets/images/galerie_slider/rea14b.jpg','public/assets/images/galerie_slider/rea14c.jpg','public/assets/images/galerie_slider/rea14d.jpg','public/assets/images/galerie_slider/rea14e.jpg','public/assets/images/galerie_slider/rea14f.jpg','public/assets/images/galerie_slider/rea14g.jpg','public/assets/images/galerie_slider/rea14h.jpg','public/assets/images/galerie_slider/rea14i.jpg','public/assets/images/galerie_slider/rea14j.jpg','public/assets/images/galerie_slider/rea14k.jpg','public/assets/images/galerie_slider/rea14l.jpg'],'Création d\'espace jardin sur-mesure','Mise en place d\'un espace jardin bois'],
    [15, 'Terrasse et clôture', 'public/assets/images/rea15.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea15a.jpg','public/assets/images/galerie_slider/rea15b.jpg','public/assets/images/galerie_slider/rea15c.jpg','public/assets/images/galerie_slider/rea15d.jpg','public/assets/images/galerie_slider/rea15e.jpg','public/assets/images/galerie_slider/rea15f.jpg'],'Aménagement de terrasse sur les toits','Pose d\'une terrasse sur les toits'],
    [16, 'Fondations jardin bois', 'public/assets/images/rea16.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea16a.jpg','public/assets/images/galerie_slider/rea16b.jpg','public/assets/images/galerie_slider/rea16c.jpg','public/assets/images/galerie_slider/rea16d.jpg','public/assets/images/galerie_slider/rea16e.jpg','public/assets/images/galerie_slider/rea16f.jpg','public/assets/images/galerie_slider/rea16g.jpg','public/assets/images/galerie_slider/rea16h.jpg','public/assets/images/galerie_slider/rea16i.jpg','public/assets/images/galerie_slider/rea16j.jpg'],'Espace jacuzzi et jardin','Fondations pour terrasse et espace jardin'],

    [17, 'Piscine bois', 'public/assets/images/rea17.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea17a.jpg','public/assets/images/galerie_slider/rea17b.jpg','public/assets/images/galerie_slider/rea17c.jpg','public/assets/images/galerie_slider/rea17d.jpg','public/assets/images/galerie_slider/rea17e.jpg','public/assets/images/galerie_slider/rea17f.jpg','public/assets/images/galerie_slider/rea17g.jpg','public/assets/images/galerie_slider/rea17h.jpg'],'Espace piscine et rangement','Espace piscine en bois'],
    [18, 'Terrasse bois composite', 'public/assets/images/rea18.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea18a.jpg','public/assets/images/galerie_slider/rea18b.jpg','public/assets/images/galerie_slider/rea18c.jpg','public/assets/images/galerie_slider/rea18d.jpg','public/assets/images/galerie_slider/rea18e.jpg','public/assets/images/galerie_slider/rea18f.jpg'],'Terrasse bois composite','Conception et fondations terrasse en bois composite'],
    [19, 'Jacuzzi et piscine', 'public/assets/images/rea19.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea19a.jpg','public/assets/images/galerie_slider/rea19b.jpg','public/assets/images/galerie_slider/rea19c.jpg','public/assets/images/galerie_slider/rea19d.jpg','public/assets/images/galerie_slider/rea19e.jpg','public/assets/images/galerie_slider/rea19f.jpg','public/assets/images/galerie_slider/rea19g.jpg','public/assets/images/galerie_slider/rea19h.jpg','public/assets/images/galerie_slider/rea19i.jpg','public/assets/images/galerie_slider/rea19j.jpg','public/assets/images/galerie_slider/rea19k.jpg','public/assets/images/galerie_slider/rea19l.jpg'],'Piscine et jacuzzi','Contour piscine'],
    [20, 'Fenêtres et parquet', 'public/assets/images/rea20.jpg', 'all fourniture', ['public/assets/images/galerie_slider/rea20a.jpg','public/assets/images/galerie_slider/rea20b.jpg','public/assets/images/galerie_slider/rea20c.jpg'],'Pose de fenêtres et parquet','Pose de fenêtres et parquet bois'],
    [21, 'Contour piscine bois composite', 'public/assets/images/rea21.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea21a.jpg','public/assets/images/galerie_slider/rea21b.jpg','public/assets/images/galerie_slider/rea21c.jpg','public/assets/images/galerie_slider/rea21d.jpg'],'Contour de piscine en bois composite','Aménagement piscine bois composite'],
    [22, 'Contour piscine et escalier', 'public/assets/images/rea22.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea22a.jpg','public/assets/images/galerie_slider/rea22b.jpg','public/assets/images/galerie_slider/rea22c.jpg'],'Escalier en bois et contour piscine sur-mesure','Aménagement pour piscine sur-mesure'],
    [23, 'Cache en bois', 'public/assets/images/rea23.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea23a.jpg','public/assets/images/galerie_slider/rea23b.jpg','public/assets/images/galerie_slider/rea23c.jpg'],'Cache en bois pour climatisation','Cache pour climatisation'],
    [24, 'Terrasse et jardinière en bois', 'public/assets/images/rea24.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea24a.jpg','public/assets/images/galerie_slider/rea24b.jpg','public/assets/images/galerie_slider/rea24c.jpg'],'Petite terrasse en bois','Terrasse et jardinière en bois'],
    [25, 'Terrasse en bois exotique', 'public/assets/images/rea25.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea25a.jpg','public/assets/images/galerie_slider/rea25b.jpg','public/assets/images/galerie_slider/rea25c.jpg','public/assets/images/galerie_slider/rea25d.jpg','public/assets/images/galerie_slider/rea25e.jpg','public/assets/images/galerie_slider/rea25f.jpg','public/assets/images/galerie_slider/rea25g.jpg','public/assets/images/galerie_slider/rea25h.jpg','public/assets/images/galerie_slider/rea25i.jpg','public/assets/images/galerie_slider/rea25j.jpg','public/assets/images/galerie_slider/rea25k.jpg','public/assets/images/galerie_slider/rea25l.jpg','public/assets/images/galerie_slider/rea25m.jpg'],'Terrase en bois exotique et Aménagement jardin','Espace jardin en bois exotique'],
    [26, 'Fondations terrasse bois', 'public/assets/images/rea26.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea26a.jpg','public/assets/images/galerie_slider/rea26b.jpg'],'Fondations de terrasse en bois','Terrasse et pallisade en bois'],
    [27, 'Placards salle de bain en bois', 'public/assets/images/rea52.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea52a.jpg'],'Pose de portes de dressing','Pose de portes de dressing'],
    [28, 'Portes', 'public/assets/images/rea53.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea53a.jpg'],'Pose de portes','Pose de portes'],
    [29, 'Création d\'espace bois', 'public/assets/images/rea29.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea29a.jpg','public/assets/images/galerie_slider/rea29b.jpg','public/assets/images/galerie_slider/rea29c.jpg','public/assets/images/galerie_slider/rea29d.jpg','public/assets/images/galerie_slider/rea29e.jpg','public/assets/images/galerie_slider/rea29f.jpg','public/assets/images/galerie_slider/rea29g.jpg','public/assets/images/galerie_slider/rea29h.jpg','public/assets/images/galerie_slider/rea29i.jpg','public/assets/images/galerie_slider/rea29j.jpg','public/assets/images/galerie_slider/rea29k.jpg','public/assets/images/galerie_slider/rea29l.jpg'],'Création d\'espace jardin en bois','Aménagement extérieur avec piscine'],
    [30, 'Escalier en bois', 'public/assets/images/rea30.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea30a.jpg','public/assets/images/galerie_slider/rea30b.jpg','public/assets/images/galerie_slider/rea30c.jpg','public/assets/images/galerie_slider/rea30d.jpg','public/assets/images/galerie_slider/rea30e.jpg','public/assets/images/galerie_slider/rea30f.jpg'],'Création d\'escalier en bois sur-mesure','Conception sur-mesure d\'escaliers en bois'],
    [31, 'Pergola', 'public/assets/images/rea31.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea31a.jpg','public/assets/images/galerie_slider/rea31b.jpg','public/assets/images/galerie_slider/rea31c.jpg'],'Conception de pergola','Création de pergola sur-mesure'],
    [32, 'Palissade', 'public/assets/images/rea32.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea32a.jpg','public/assets/images/galerie_slider/rea32b.jpg'],'Aménagement de pallisade en bois','Palissade en bois'],

    [33, 'Plan de travail', 'public/assets/images/rea33.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea33a.jpg','public/assets/images/galerie_slider/rea33b.jpg','public/assets/images/galerie_slider/rea33c.jpg'],'Pose de plan de travail','Plan de travail pour cuisine'],
    [34, 'Parquet en bois', 'public/assets/images/rea34.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea34a.jpg','public/assets/images/galerie_slider/rea34b.jpg','public/assets/images/galerie_slider/rea34c.jpg','public/assets/images/galerie_slider/rea34d.jpg','public/assets/images/galerie_slider/rea34e.jpg'],'Pose de parquet en bois','Parquet en bois'],
    [35, 'Fenêtres', 'public/assets/images/rea35.jpg', 'all fourniture', ['public/assets/images/galerie_slider/rea35a.jpg','public/assets/images/galerie_slider/rea35b.jpg','public/assets/images/galerie_slider/rea35c.jpg','public/assets/images/galerie_slider/rea35d.jpg','public/assets/images/galerie_slider/rea35e.jpg'],'Baies vitrées en PVC','Pose de baies vitrées en PVC'],
    [36, 'Meuble salle de bain', 'public/assets/images/rea36.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea36a.jpg','public/assets/images/galerie_slider/rea36b.jpg'],'Installation de meuble en bois de salle de bain','Aménagement salle de bain'],
    [37, 'Terrasse en bois', 'public/assets/images/rea37.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea37a.jpg','public/assets/images/galerie_slider/rea37b.jpg'],'Terrasse en bois','Terrasse en bois'],
    [38, 'Dressing et fenêtres', 'public/assets/images/rea38.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea38a.jpg','public/assets/images/galerie_slider/rea38b.jpg','public/assets/images/galerie_slider/rea38c.jpg'],'Aménagement de dressing','Pose de dressing et aménagement intérieur'],
    [39, 'Plan de travail et crédence', 'public/assets/images/rea39.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea39a.jpg'],'Pose de plan de travail en bois','Plan de travail bois pour cuisine'],
    [40, 'Plan de travail', 'public/assets/images/rea40.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea40a.jpg'],'Plan de travail pour cuisine','Pose de plan de travail'],
    [41, 'Contour piscine', 'public/assets/images/rea11.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea11a.jpg','public/assets/images/galerie_slider/rea11b.jpg','public/assets/images/galerie_slider/rea11c.jpg','public/assets/images/galerie_slider/rea11d.jpg','public/assets/images/galerie_slider/rea11e.jpg','public/assets/images/galerie_slider/rea11f.jpg','public/assets/images/galerie_slider/rea11g.jpg','public/assets/images/galerie_slider/rea11h.jpg'],'Contour piscine','Conception de contour piscine'],
    [42, 'Terrasse et jardinière', 'public/assets/images/rea12.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea12a.jpg','public/assets/images/galerie_slider/rea12b.jpg','public/assets/images/galerie_slider/rea12c.jpg','public/assets/images/galerie_slider/rea12d.jpg','public/assets/images/galerie_slider/rea12e.jpg','public/assets/images/galerie_slider/rea12f.jpg','public/assets/images/galerie_slider/rea12g.jpg','public/assets/images/galerie_slider/rea12h.jpg','public/assets/images/galerie_slider/rea12i.jpg'],'Jardinière et espace jacuzzi','Conception d\'espace jacuzzi avec jardinière'],
    [43, 'Table et banquette', 'public/assets/images/rea43.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea43a.jpg'],'Espace jardin','Espace extérieur en bois'],
    [44, 'Dressing', 'public/assets/images/rea44.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea44a.jpg','public/assets/images/galerie_slider/rea44b.jpg'],'Conception de dressing sur-mesure','Dressing en bois sur-mesure'],
    [45, 'Espace piscine bois', 'public/assets/images/rea4.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea4a.jpg','public/assets/images/galerie_slider/rea4b.jpg','public/assets/images/galerie_slider/rea4c.jpg','public/assets/images/galerie_slider/rea4d.jpg'],'Espace de piscine détente en bois','Contour piscine et jacuzzi'],
    [47, 'Dressing bois', 'public/assets/images/rea47.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea47a.jpg','public/assets/images/galerie_slider/rea47b.jpg','public/assets/images/galerie_slider/rea47c.jpg'],'Installation de dressing en  bois','Aménagement de dressing'],
    [48, 'Plan de travail', 'public/assets/images/rea48.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea48a.jpg'],'Pose de plan de travail','Installation de plan de travail pour cuisine'],

    [49, 'Parquet bois', 'public/assets/images/rea49.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea49a.jpg','public/assets/images/galerie_slider/rea49b.jpg'],'Pose de parquet en bois','Installation de parquet en bois'],
    [50, 'Pose de plan de travail', 'public/assets/images/rea50.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea50a.jpg'],'Plan de travail pour cuisine','Pose de plan de travail'],
    [51, 'Plinthes escaliers', 'public/assets/images/rea51.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea51a.jpg'],'Plinthes en bois pour escaliers','Installation et conception de plinthes en bois'],
    [52, 'Espace jacuzzi', 'public/assets/images/rea27.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea27a.jpg','public/assets/images/galerie_slider/rea27b.jpg','public/assets/images/galerie_slider/rea27c.jpg','public/assets/images/galerie_slider/rea27d.jpg','public/assets/images/galerie_slider/rea27e.jpg','public/assets/images/galerie_slider/rea27f.jpg','public/assets/images/galerie_slider/rea27g.jpg','public/assets/images/galerie_slider/rea27h.jpg','public/assets/images/galerie_slider/rea27i.jpg','public/assets/images/galerie_slider/rea27j.jpg'],'Espace jacuzzi','Création d\'un espace jacuzzi'],
    [53, 'Espace piscine bois', 'public/assets/images/rea28.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea28a.jpg','public/assets/images/galerie_slider/rea28b.jpg','public/assets/images/galerie_slider/rea28c.jpg','public/assets/images/galerie_slider/rea28d.jpg','public/assets/images/galerie_slider/rea28e.jpg','public/assets/images/galerie_slider/rea28f.jpg','public/assets/images/galerie_slider/rea28g.jpg','public/assets/images/galerie_slider/rea28h.jpg'],'Conception d\'un espace piscine','Aménagement extérieur pour jardin'],
    [54, 'Mur', 'public/assets/images/rea54.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea54a.jpg'],'Mur en bois sur-mesure','Aménagement intérieur en bois'],
    [55, 'Dressing', 'public/assets/images/rea55.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea55a.jpg'],'Installation et conception de dressing en bois','Pose de dressing'],
    [56, 'Fenêtres PVC', 'public/assets/images/rea56.jpg', 'all fourniture', ['public/assets/images/galerie_slider/rea56a.jpg'],'Pose de fenêtres PVC','Installation de fenêtres PVC'], 
    [58, 'Pose abris', 'public/assets/images/rea58.jpg', 'all exterieur', ['public/assets/images/galerie_slider/rea58a.jpg'],'Abris pour porte d\'entrée','Pose d\'abris'],
    [59, 'Dressing', 'public/assets/images/rea59.jpg', 'all interieur', ['public/assets/images/galerie_slider/rea59a.jpg'],'Installation de dressing','Installation de dressing bois'], 
];

var  html = "";

for (var i = 0; i < realisation.length; i++) {
    var name = realisation[i][1],
        mainImg = realisation[i][2],
        filter = realisation[i][3],
        altImg = realisation[i][5],
        titleImg = realisation[i][6],
        galeryContent = '';
    
    for(var t = 0; t < realisation[i][4].length; t++){
        galeryContent += '<img src="' + realisation[i][4][t] + '" alt="galery-slider">';
    } 
    


    html += '<div class="galerie-item inner col-12 col-md-6 col-lg-4 col-xl-3 ' + filter + '">\
                <figure class="content">\
                    <img src="' + mainImg + '" alt="' + altImg + '" title="' + titleImg + '">\
                </figure>\
                <div class="title"><h3>' + name + '</h3></div>\
                <div class="lightbox-panel">\
                    <div class="lightbox-slider">\
                        ' + galeryContent + '\
                    </div>\
                    <a class="close-panel href="#">x</a>\
                </div>\
                <div class="lightbox"></div>\
            </div>';

    $('.pageUn').html(html);
} 

$('.galerie-item').slice(16).hide();


    /* ------------------------------------------------------------------------ */
    /* REALISATION PAGINATION */
    /* ------------------------------------------------------------------------ */

var totalItem = realisation.length,
    numberPage = (totalItem/16) + 1;
for(var i = 1; i < numberPage; i++){
    $('.pagination-list').append('<li><button class="galerie-btn" data-pos="'+ [i] +'">'+ [i] +'</button></li>');
}

$('.pagination-list li:first').children().addClass('active');

$('.galerie-btn').on(click, function(){
/*////// get value of page - button */
    var btnPosition = $(this).attr('data-pos');
/*////// prepare value of length with position */
    var s = 16 * (btnPosition - 1),
        e = 16 + s;
/*////// hide old items, show news one from calcul */
    $('.galerie-item').hide().slice(s,e).show();
/*////// update button active style*/
    $('.galerie-btn').removeClass('active');
    $(this).addClass('active');

    $('.tri-item').removeClass('active');
    $('.reset').addClass('active');

    $('html, body').animate({
        scrollTop:$('#top-galerie').offset().top
    }, 'slow');
});

    /* ------------------------------------------------------------------------ */
    /* REALISATION TRI */
    /* ------------------------------------------------------------------------ */

$('.tri-item').on(click, function(){
/*////// change filter button style */
    $('.tri-item').removeClass('active');
/*////// reset grid before filter */
    $('.feedback').remove();
    $(this).addClass('active');
    var btnPositionOnReset = $('.galerie-btn.active').attr('data-pos');
    var st = 16 * (btnPositionOnReset - 1),
        en = 16 + st;
   $('.galerie-item').hide().slice(st,en).show();
/*////// store filtre type */
    var filtreType = $(this).attr('data-filtre');
/*////// filter item */
    $('.galerie-item').each(function(){
        if($(this).hasClass(filtreType)){
            $(this).slice(16).hide();
        } else{
            $(this).hide();
        }
    }); 

/*////// add feedback if no item */
    var numberItem = $('.' + filtreType + ':visible').length;
    if(numberItem == 0){
        $('.galerie-list').append('<span class="feedback">Il n\'y a pas de réalisations de ce type sur cette page.</span>');
    }
});


    /* ------------------------------------------------------------------------ */
    /* REALISATION SETUP MODAL */
    /* ------------------------------------------------------------------------ */



$(".galerie-item.inner").click(function(){
    $(this).children(".lightbox, .lightbox-panel").fadeIn(300);
    $(this).children('.lightbox-panel').children('.lightbox-slider').slick({
        prevArrow: '<button type="button" class="slick-prev"><i class="icomoon icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icomoon icon-arrow-right"></i></button>',
        adaptiveHeight: true,
    });
});
$(".lightbox").click(function(){
    $(".lightbox, .lightbox-panel").fadeOut(300);
});
$(".close-panel").click(function(){
    $(".lightbox, .lightbox-panel").fadeOut(300);
});
$('.lightbox-panel, .lightbox').click(function(){
    event.stopPropagation();
});



}); //end jquery

/* $('.tri-item').on(click, function(){
////// change filter button style 
    $('.tri-item').removeClass('active');
////// reset grid before filter 
    $('.feedback').remove();
    $(this).addClass('active');
    var btnPositionOnReset = $('.galerie-btn.active').attr('data-pos');
    var st = 16 * (btnPositionOnReset - 1),
        en = 16 + st;
   $('.galerie-item').hide().slice(st,en).show();////// store filtre type 
    var filtreType = $(this).attr('data-filtre');
////// filter item 
    $('.galerie-item').each(function(){
        if($(this).hasClass(filtreType)){
            $(this).slice(16).hide();
        } else{
            $(this).hide();
        }
    }); 

////// add feedback if no item 
    var numberItem = $('.' + filtreType + ':visible').length;
    if(numberItem == 0){
        $('.galerie-list').append('<span class="feedback">Il n\'y a pas de réalisations de ce type sur cette page.</span>');
    }
});*/